@import "nullstyle.scss";

@font-face {
  src: url("Fonts/Rubik-SemiBold.ttf");
  font-family: "Rubik-Semibold";
}

@font-face {
  src: url("Fonts/Rubik-Regular.ttf");
  font-family: "Rubik-Regular";
}

$font-stack: "Rubik-Regular", system-ui, Arial, Helvetica;

body {
  font-family: $font-stack;
  background-color: rgba(223, 223, 223, 0.1);
}
p {
  font-size: 1.1rem;
  line-height: 1.5;
  word-spacing: 0.1rem;
}
h1 {
  font-family: "Rubik-Semibold";
  font-size: 2.125rem;
}

h2 {
  font-family: "Rubik-Semibold";
  font-size: 1.6rem;
}
h3,
h4 {
  font-size: 1.3rem;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  main {
    margin: 3%;
    flex: 1;
  }
}
