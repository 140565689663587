.sk-q {
  border: 2px solid rgba(27, 27, 27, 0.2);
  margin: 1rem 0rem;
  padding: 0.5rem;
  border-radius: 10px;
  animation: skeleton 1s ease infinite alternate;
  width: 100%;
  .sk-q__header {
    background-color: rgba(128, 128, 128, 0.8);
    width: 10rem;
    height: 1.5rem;
    margin: 0.5rem 0rem;
  }
  .sk-q__line {
    height: 1.2rem;
    background-color: rgba(128, 128, 128, 0.8);
    margin: 0.25rem 0rem;
  }
  .sk-q__ans {
    height: 1.5rem;
    background-color: rgba(128, 128, 128, 0.8);
    margin: 0.25rem 0rem;
    width: 5rem;
  }
}

@keyframes skeleton {
  to {
    opacity: 0.2;
  }
}
