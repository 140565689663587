footer {
  // min-height: 4rem;
  width: 100%;
  // background-color: rgb(167, 190, 233);
  border-top: 2px solid rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  .footer__icons {
    display: flex;
    div,
    a {
      display: flex;
      height: 2.5rem;
      margin: 1rem 1rem;

      p {
        margin: auto 0;
        padding-left: 0.3rem;
      }
    }
  }
}

@media (max-width: 340px) {
  footer {
    .footer__icons {
      div {
        margin: 0.5rem 0.3rem;
      }
    }
  }
}
