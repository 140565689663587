header {
  display: flex;
  justify-content: space-between;

  background-color: rgba(97, 161, 153, 0.2);
  box-shadow: 0px 0px 10px 5px rgba(34, 60, 80, 0.3);
  div {
    display: flex;
    height: 3rem;
    cursor: pointer;
    img {
      padding: 0.4rem 0 0.4rem 0.4rem;
    }
    h2 {
      padding-left: 0.5rem;
      font-size: 1.9rem;
      font-family: "Rubik-Semibold";
      margin: auto 0rem;
    }
  }
}

nav {
  display: flex;
  ul {
    display: flex;
    align-items: center;
    height: 100%;
    li {
      display: flex;
      height: 100%;
      font-size: 1.125rem;
      font-weight: 600;
      padding: 0rem 0.5rem;
      // color: rgba(134, 123, 123, 1);
      color: rgba(34, 60, 80, 0.7);

      &:hover {
        // color: rgb(127, 97, 97);
        color: rgba(34, 60, 80, 1);
      }
      a {
        padding-top: 1rem;
        height: 100%;
      }
    }
  }
}

.mob-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.7rem;
  span {
    width: 25px;
    height: 3px;
    margin: 2px 0px;
    background-color: black;
    transition: 0.3s;
  }
}

.mob-btn.open {
  span:first-child {
    transform: rotate(45deg) translateY(5px);
    transition: 0.3s;
  }
  span:nth-child(2) {
    display: none;
  }
  span:last-child {
    transform: rotate(-45deg) translateY(-5px);
    transition: 0.3s;
  }
}

@media (max-width: 1130px) {
  header {
    div {
      h2 {
        font-size: 1.5rem;
      }
    }
  }
}
@media (max-width: 460px) {
  header {
    div {
      h2 {
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 1050px) {
  nav {
    position: absolute;
    top: 3rem;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);

    ul {
      flex-direction: column;
      width: 70%;
      z-index: 10;
      background-color: white;
      li {
        height: 3rem;
        font-size: 1.4rem;
      }
    }
  }
  .mob-menu {
    transform: translateX(0);
    z-index: 1;
    backdrop-filter: blur(2px);
    transition: transform 0.2s;
    ul {
      z-index: 10;
    }
  }
}

@media (min-width: 1050px) {
  .mob-btn {
    display: none;
  }
}
