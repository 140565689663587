.about {
  padding: 0px 6%;
  a {
    color: rgb(0, 0, 255);
    &:visited {
      color: rgb(0, 0, 255);
    }
  }
  h2 {
    text-align: left;
    padding: 0.25rem 0 0;
  }
  p {
    padding: 0.5rem 0;
    font-size: 1.25rem;
    line-height: 1.9rem;
  }

  .about__author {
    padding-top: 0.5rem;
    display: flex;
    img {
      max-width: 250px;
    }
    p {
      padding-left: 1rem;
    }
  }
}
