.question {
  border: 2px solid rgba(27, 27, 27, 1);
  margin: 1rem 0rem;
  padding-bottom: 0.5rem;
  border-radius: 10px;
  p {
    font-size: 1.25rem;
  }

  .question__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(27, 27, 27, 0.4);
    padding: 0.5rem;
    background-color: rgba(173, 216, 230, 0.4);
    h3 {
      padding-left: 0.5rem;
      font-family: "Rubik-Semibold";
      width: 120px;
    }
  }
  .question__add {
    padding-top: 0.5rem;
    p {
      padding-left: 0.5rem;
    }
    .content {
      display: flex;
      justify-content: center;
      padding: 1rem 0rem;
      img {
        max-width: 60%;
      }
      p {
        font-size: 1.25rem;
      }
    }
  }
  .question__text {
    padding: 0.5rem;
    p {
      padding: 0.25rem 0;
    }
  }

  h4 {
    padding-left: 0.5rem;
  }

  .answer {
    padding: 0.25rem 0.5rem;
    p {
      padding: 0.25rem 0rem;
      word-wrap: break-word;
    }
    span {
      font-family: "Rubik-Semibold";
    }
    .answer__source_one {
      overflow-x: auto;
    }
    .answer__source_many {
      font-family: "Rubik-Semibold";
      & ~ p {
        padding-left: 0.5rem;
      }
    }
  }
}
.ans__arrow {
  display: flex;
  align-items: flex-end;
  border-top: 1px solid #000;
  padding: 0.3rem 0rem 0.1rem;
  cursor: pointer;

  & div {
    width: 1rem;
    margin-left: 2rem;
    .arrow {
      transition: all 1s;

      &.o {
        transform: rotate(180deg);
      }
    }
  }
}

.ans {
  max-height: 1800px;
  overflow: hidden;
  transition: max-height 0.5s;
  // transition-timing-function: linear;

  &.close {
    max-height: 0;
    overflow: hidden;
  }
}
