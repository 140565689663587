.search {
  margin-top: 1.5rem;
  form {
    display: flex;
    justify-content: center;
    .search__input {
      font-size: 1.125rem;
      height: 2.5rem;
      border: 1px solid rgba(27, 27, 27, 0.4);
      padding-left: 0.5rem;
      border-radius: 5px;
      width: 100%;
    }

    .search__start-date,
    .search__end-date {
      font-weight: 900;
      font-size: 1.125rem;
      min-width: 150px;
      height: 2.5rem;
      border: 1px solid rgba(27, 27, 27, 0.4);
      border-radius: 5px;
      padding: 0rem 0.5rem;
      border-left: unset;
    }
    .search__button {
      font-size: 1.125rem;
      height: 2.5rem;
      border: 1px solid rgba(27, 27, 27, 0.4);
      padding: 0rem 0.5rem;
      border-radius: 5px;
    }
  }
}

.main-content {
  display: flex;
  margin-top: 1rem;
  .main-content__random {
    width: 50%;

    .main-content__refresh {
      display: flex;
      .refresh {
        display: flex;
        cursor: pointer;
        & div {
          margin-left: 1rem;
          width: 1.6rem;
          .refresh__arrow {
            transition: all 1s;

            &.r {
              transform: rotate(-360deg);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .main-content {
    flex-direction: column;

    .main-content__random {
      width: 100%;
    }
    .main-content__right {
      width: 100%;
      .main-content__tournaments {
        margin-left: unset;
      }
      .main-content__banner {
        margin-left: unset;
      }
    }
  }
}

.main-content__right {
  width: 50%;
}

.main-content__tournaments {
  margin-left: 2rem;

  .tournaments__header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0rem 0.2rem;
    h3:last-child {
      padding-right: 0.25rem;
    }
  }

  .tournaments__item {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(27, 27, 27, 0.2);
    align-items: center;
    &:hover {
      background-color: rgba(97, 161, 153, 0.1);
    }
    a {
      padding: 0.5rem 0rem;
      font-size: 1.3rem;
      cursor: pointer;
      color: rgb(16, 74, 55);
    }
  }

  .tournaments__footer {
    display: flex;
    justify-content: center;
    padding-top: 0.4rem;
    div {
      width: 1.6rem;
      img {
        cursor: pointer;
      }
    }
    p {
      margin: auto 1rem;
      font-size: 1.25rem;
    }
  }
}

.main-content__banner {
  margin-left: 2rem;
  margin-top: 1rem;
  border: 3px solid rgb(27, 27, 27);
  border-radius: 20px;
  padding: 1.2rem;
  background-color: rgba(250, 250, 210, 0.5);
  h2 {
    text-align: center;
  }
  p {
    text-align: center;
    padding: 1rem 0;
  }
}
