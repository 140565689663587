.addlink_container {
  .addlink {
    display: flex;
    justify-content: center;

    input {
      width: 60%;
      // margin: 6px 0px;
      font-size: 1.3rem;
      padding: 0px 18px;
      border: 1px solid #1b1b1b80;
      border-radius: 6px;
    }
    .btn-elem {
      margin: 0rem 0rem 0rem 2rem;
    }
  }

  .addlink__errorsFilling {
    color: rgb(206, 66, 124);
    padding-top: 0.2rem;
  }

  .addlink__message {
    text-align: center;
    padding-top: 1rem;
    font-size: 1.5rem;
  }

  .spinner {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }

  .tournament__header {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 1rem auto;
    .tournament__header-t {
      padding: unset;
    }
  }

  .addlink__buttons {
    display: flex;
    justify-content: space-around;
    padding: 0 10%;
  }
}

.edit-t {
  textarea {
    width: 100%;
    font-size: 1.2rem;
    // font-weight: 600;
    border: 1px solid #000;
    padding: 0.3rem 0rem 0.3rem 0.3rem;
  }
  .edit-t__top {
    padding-top: 1rem;
    label {
      input {
        border: 1px solid #000;
        padding-left: 0.3rem;
        font-weight: 900;
        font-size: 1rem;
        min-height: 2rem;
      }
      input[type="text"] {
        width: 100%;
      }
    }
  }
  .edit-q__container {
    margin-top: 2rem;
    border: 4px solid rgba(97, 161, 153, 0.9);
    padding: 1rem;
    .edit-q__numbers {
      display: flex;
      label {
        display: flex;
        p {
          margin: auto 0;
        }
        input {
          border: 1px solid #000;
          padding-left: 0.5rem;
          font-weight: 900;
          font-size: 1rem;
          min-height: 2rem;
          width: 5rem;
        }
      }
      & :last-child {
        margin-left: 1rem;
      }
    }
    & > label {
      p {
        padding-top: 1rem;
      }
    }
  }
}
