.btn-elem {
  display: flex;
  justify-content: center;
  button {
    padding: 6px 18px;
    font-size: 15px;
    border-radius: 6px;
    margin: 5px 0px;
    background: rgba(97, 161, 153, 0.9);
    color: white;
    &:hover {
      font-weight: bold;
      box-shadow: 2px 2px 3px 2px rgba(34, 60, 80, 0.2);
    }
  }
}
