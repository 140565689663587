.tournament__header {
  margin-top: 2rem;
  h3 {
    padding: 1rem 0;
    font-weight: bold;
    span {
      font-weight: normal;
    }
  }
  .tournament__header-t {
    display: flex;
    padding-bottom: 1rem;
    p {
      padding-left: 2rem;
      font-size: 1.25rem;
      margin-top: auto;
    }
  }
  .tournament__header-m {
    display: flex;
    h3:nth-child(n + 2) {
      padding-left: 3rem;
    }
  }
}

.tournament__content {
  .question {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

// @media (max-width: 800px) {
//   .tournament_content {
//     .question {
//       width: unset;
//     }
//   }
// }
